<template>
  <div class="distribute-commission setting-wrap">
    <ValidationObserver ref="observer" @submit.prevent="onSave">
      <CForm>
        <CCard>
          <CCardBody>
            <CRow>
              <CCol col="12">
                <CRow v-for="commission in commissions" :key="commission.id">
                  <CCol col="12">
                    <div class="pb-1">{{commission.platform}}</div>
                    <CCard class="mb-2">
                      <CCardBody class="p-3">
                        <CRow>
                          <CCol sm="12" md="2" class="block-col">
                            <ValidationProvider
                              rules="required|min_value:0"
                              :name="$t(`DISTRIBUTE_COMMISSION_PAGE_FIELD_NAME_VALIDATE_${commission.cycle_type}`, {
                                platform: commission.platform,
                                type: commission.cycle_type
                              })"
                              v-slot="{ errors }"
                            >
                              <CRow class="custom-input" alignVertical="center">
                                <CCol
                                  xl="5"
                                  class="block-label"
                                >{{$t('DISTRIBUTE_COMMISSION_PAGE_LABEL_BLOCK', {type: commission.cycle_type})}}</CCol>
                                <CCol xl="7" class="content">
                                  <currency-input
                                    class="input-field border-radius"
                                    :precision="0"
                                    v-model="commission.cycle"
                                  />
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol xl="12" v-if="errors" class="error-msg">{{errors[0]}}</CCol>
                              </CRow>
                            </ValidationProvider>
                          </CCol>

                          <CCol sm="12" md="5">
                            <ValidationProvider
                              rules="required|min_value:0"
                              :name="$t('DISTRIBUTE_COMMISSION_PAGE_FIELD_NAME_VALIDATE_MIN_VALUE', {platform: commission.platform})"
                              v-slot="{ errors }"
                            >
                              <CRow class="custom-input" alignVertical="center">
                                <CCol xl="4">{{$t("DISTRIBUTE_COMMISSION_PAGE_LABEL_MIN_VALUE")}}</CCol>
                                <CCol xl="8" class="content">
                                  <currency-input
                                    class="input-field"
                                    :precision="3"
                                    v-model="commission.min_amount"
                                  />
                                  <span class="unit">{{commission.amount_unit}}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol xl="4"></CCol>
                                <CCol xl="8" v-if="errors" class="error-msg">{{errors[0]}}</CCol>
                              </CRow>
                            </ValidationProvider>
                          </CCol>

                          <CCol sm="12" md="5">
                            <ValidationProvider
                              rules="required|min_value:0"
                              :name="$t('DISTRIBUTE_COMMISSION_PAGE_GET_COMMISSION_MIN_FIELD_NAME', {platform: commission.platform})"
                              v-slot="{ errors }"
                            >
                              <CRow class="custom-input" alignVertical="center">
                                <CCol
                                  xl="4"
                                >{{$t("DISTRIBUTE_COMMISSION_PAGE_GET_COMMISSION_MIN_LABEL")}}</CCol>
                                <CCol xl="8" class="content">
                                  <currency-input
                                    class="input-field"
                                    :precision="3"
                                    v-model="commission.min_amount_withdrawal"
                                  />
                                  <span class="unit">{{commission.amount_unit}}</span>
                                </CCol>
                              </CRow>
                              <CRow>
                                <CCol xl="4"></CCol>
                                <CCol xl="8" v-if="errors" class="error-msg">{{errors[0]}}</CCol>
                              </CRow>
                            </ValidationProvider>
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </CCol>
              <CCol col="12" class="mt-2 text-right" v-if="hasUpdate">
                <CButton
                  class="pl-4 pr-4"
                  color="primary"
                  type="submit"
                >{{$t("DISTRIBUTE_COMMISSION_PAGE_BUTTON_SAVE")}}</CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CForm>
    </ValidationObserver>
    <CCard>
      <CCardBody>
        <div class="font-weight-bold m-1">{{$t("DISTRIBUTE_COMMISSION_PAGE_TITLE_HISTORY")}}</div>
        <CDataTable
          class="mb-0"
          outlined
          striped
          hover
          :items="transactions"
          :fields="tableHeaders"
          head-color="light"
          no-sorting
        >
          <td
            class="text-center"
            slot="order"
            slot-scope="{ item, index }"
          >{{ (index+1) + (activePage-1)*limit }}</td>
          <td class="text-left" slot="platform" slot-scope="{ item }">{{ item.platform }}</td>
          <td
            class="text-left"
            slot="content"
            slot-scope="{ item }"
          >{{item.cycle_type}} : {{ item.cycle }}, Min Value: {{ item.min_amount.toLocaleString() }} {{item.amount_unit}}</td>
          <td
            class="text-left"
            slot="updateAt"
            slot-scope="{ item }"
          >{{ item.updated_at | moment("YYYY-MM-DD HH:mm") }}</td>
        </CDataTable>
        <div>
          <CPagination
            :activePage.sync="activePage"
            :pages="pages"
            align="end"
            @update:activePage="pushPage"
          />
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>
import endpoints from '@/constants/endpoints';
import BigNumber from 'bignumber.js';
import { mapState, mapActions } from 'vuex';
import responseHelper from '@/utils/response-helper';

export default {
  name: 'DistributeCommission',
  data() {
    return {
      tableHeaders: [
        { key: 'order', label: '#', _classes: 'text-center' },
        { key: 'platform', label: this.$t('DISTRIBUTE_COMMISSION_PAGE_LABEL_PLATFORM'), _classes: 'text-left' },
        { key: 'content', label: this.$t('DISTRIBUTE_COMMISSION_PAGE_LABEL_CONTENT'), _classes: 'text-left' },
        { key: 'updateAt', label: this.$t('DISTRIBUTE_COMMISSION_PAGE_LABEL_UPDATE_AT'), _classes: 'text-left' },
      ],
      transactions: [],
      commissions: [],
      limit: 5,
      total: 0,
      activePage: 1,
      content: [],
      check: 0,
      hasUpdate: false,
      hasView: false,
    };
  },
  computed: {
    ...mapState({
      userPermission: (state) => state.auth.userPermission,
    }),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermission();
    const [commissions, transactions] = await Promise.all([this.getComDistribute(), this.getComDistributeHistory()]);
    if (!commissions || !transactions) {
      return;
    }

    this.commissions = commissions.items.slice();
    this.transactions = transactions.items.slice();
    this.total = transactions.total;
  },
  methods: {
    async getComDistribute() {
      try {
        const params = {
          limit: 100000000,
          offset: 0,
        };
        const result = await this.$http.get(endpoints.getComDistribute, {
          params: params,
        });

        result.data.items.forEach(item => {
          if(item.platform == 'TADA') {
            item.platform = 'ADA';
          }
        });
        return result.data;
      } catch (err) {
        return null;
      }
    },

    async getComDistributeHistory() {
      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
        };
        const history = await this.$http.get(endpoints.getComDistributeHistory, {
          params: params,
        });
        return history.data;
      } catch (err) {
        return null;
      }
    },

    async pushPage() {
      const transactions = await this.getComDistributeHistory();
      if (!transactions) {
        return;
      }

      this.transactions = transactions.items || [];
    },
    async onSave() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }

      try {
        const items = this.commissions.map((el) => {
          return {
            id: el.id,
            cycle: el.cycle,
            min_amount: el.min_amount,
            min_amount_withdrawal: el.min_amount_withdrawal,
          };
        });

        const result = await this.$http.post(endpoints.updateComDistribute, {
          items: items,
        });
        this.activePage = 1;
        const transactions = await this.getComDistributeHistory();
        this.transactions = transactions.items.slice();
        this.total = transactions.total;
        this.$notify({
          group: 'notifications',
          type: 'success',
          title: this.$t('DISTRIBUTE_COMMISSION_PAGE_TITLE_NOTIFY_SUCCESS'),
          text: this.$t('DISTRIBUTE_COMMISSION_PAGE_TEXT_NOTIFY_SUCCESS'),
        });
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('DISTRIBUTE_COMMISSION_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    checkPermission() {
      if (this.userPermission.length > 0) {
        this.userPermission.forEach((item) => {
          if (item.name === 'UPDATE_PAYOUT_CONFIG') {
            this.hasUpdate = true;
          }
          if (item.name === 'VIEW_LIST_PAYOUT_CONFIG') {
            this.hasView = true;
          }
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.distribute-commission /deep/ {
  .form-group {
    margin-bottom: 0;
  }
  .form-control {
    text-align: right;
  }
  .custom-input {
    padding-top: 4px;
    .col-xl-3 {
      text-align: right;
      padding-right: 0;
      @media (max-width: 1200px) {
        text-align: left;
      }
    }
    .content {
      display: flex;
      align-items: center;
      .input-field {
        padding: 6px 12px;
        width: 90%;
        border: 1px solid #d8dbe0;
        border-radius: 0.25rem 0 0 0.25rem;
        text-align: right;
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .border-radius {
        border-radius: 0.25rem !important;
      }
      .unit {
        margin: 0 auto;
        background: #f0f3f5;
        padding: 6px 0;
        text-align: center;
        border: 1px solid #e4e7ea;
        border-radius: 0 0.25rem 0.25rem 0;
        width: 90px;
      }
    }
  }
  .card-body {
    .card {
      margin-bottom: 15px !important;
    }
    .block-col {
      padding-right: 0;
      .block-label {
        padding-right: 0;
      }
    }

    .error-msg {
      color: red;
      font-size: 14px;
      line-height: 16px;
      padding-top: 5px;
    }
  }
}
</style>
