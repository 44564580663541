var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"distribute-commission setting-wrap"},[_c('ValidationObserver',{ref:"observer",on:{"submit":function($event){$event.preventDefault();return _vm.onSave($event)}}},[_c('CForm',[_c('CCard',[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"col":"12"}},_vm._l((_vm.commissions),function(commission){return _c('CRow',{key:commission.id},[_c('CCol',{attrs:{"col":"12"}},[_c('div',{staticClass:"pb-1"},[_vm._v(_vm._s(commission.platform))]),_c('CCard',{staticClass:"mb-2"},[_c('CCardBody',{staticClass:"p-3"},[_c('CRow',[_c('CCol',{staticClass:"block-col",attrs:{"sm":"12","md":"2"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0","name":_vm.$t(("DISTRIBUTE_COMMISSION_PAGE_FIELD_NAME_VALIDATE_" + (commission.cycle_type)), {
                              platform: commission.platform,
                              type: commission.cycle_type
                            })},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('CRow',{staticClass:"custom-input",attrs:{"alignVertical":"center"}},[_c('CCol',{staticClass:"block-label",attrs:{"xl":"5"}},[_vm._v(_vm._s(_vm.$t('DISTRIBUTE_COMMISSION_PAGE_LABEL_BLOCK', {type: commission.cycle_type})))]),_c('CCol',{staticClass:"content",attrs:{"xl":"7"}},[_c('currency-input',{staticClass:"input-field border-radius",attrs:{"precision":0},model:{value:(commission.cycle),callback:function ($$v) {_vm.$set(commission, "cycle", $$v)},expression:"commission.cycle"}})],1)],1),_c('CRow',[(errors)?_c('CCol',{staticClass:"error-msg",attrs:{"xl":"12"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('CCol',{attrs:{"sm":"12","md":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0","name":_vm.$t('DISTRIBUTE_COMMISSION_PAGE_FIELD_NAME_VALIDATE_MIN_VALUE', {platform: commission.platform})},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('CRow',{staticClass:"custom-input",attrs:{"alignVertical":"center"}},[_c('CCol',{attrs:{"xl":"4"}},[_vm._v(_vm._s(_vm.$t("DISTRIBUTE_COMMISSION_PAGE_LABEL_MIN_VALUE")))]),_c('CCol',{staticClass:"content",attrs:{"xl":"8"}},[_c('currency-input',{staticClass:"input-field",attrs:{"precision":3},model:{value:(commission.min_amount),callback:function ($$v) {_vm.$set(commission, "min_amount", $$v)},expression:"commission.min_amount"}}),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(commission.amount_unit))])],1)],1),_c('CRow',[_c('CCol',{attrs:{"xl":"4"}}),(errors)?_c('CCol',{staticClass:"error-msg",attrs:{"xl":"8"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1),_c('CCol',{attrs:{"sm":"12","md":"5"}},[_c('ValidationProvider',{attrs:{"rules":"required|min_value:0","name":_vm.$t('DISTRIBUTE_COMMISSION_PAGE_GET_COMMISSION_MIN_FIELD_NAME', {platform: commission.platform})},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('CRow',{staticClass:"custom-input",attrs:{"alignVertical":"center"}},[_c('CCol',{attrs:{"xl":"4"}},[_vm._v(_vm._s(_vm.$t("DISTRIBUTE_COMMISSION_PAGE_GET_COMMISSION_MIN_LABEL")))]),_c('CCol',{staticClass:"content",attrs:{"xl":"8"}},[_c('currency-input',{staticClass:"input-field",attrs:{"precision":3},model:{value:(commission.min_amount_withdrawal),callback:function ($$v) {_vm.$set(commission, "min_amount_withdrawal", $$v)},expression:"commission.min_amount_withdrawal"}}),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(commission.amount_unit))])],1)],1),_c('CRow',[_c('CCol',{attrs:{"xl":"4"}}),(errors)?_c('CCol',{staticClass:"error-msg",attrs:{"xl":"8"}},[_vm._v(_vm._s(errors[0]))]):_vm._e()],1)]}}],null,true)})],1)],1)],1)],1)],1)],1)}),1),(_vm.hasUpdate)?_c('CCol',{staticClass:"mt-2 text-right",attrs:{"col":"12"}},[_c('CButton',{staticClass:"pl-4 pr-4",attrs:{"color":"primary","type":"submit"}},[_vm._v(_vm._s(_vm.$t("DISTRIBUTE_COMMISSION_PAGE_BUTTON_SAVE")))])],1):_vm._e()],1)],1)],1)],1)],1),_c('CCard',[_c('CCardBody',[_c('div',{staticClass:"font-weight-bold m-1"},[_vm._v(_vm._s(_vm.$t("DISTRIBUTE_COMMISSION_PAGE_TITLE_HISTORY")))]),_c('CDataTable',{staticClass:"mb-0",attrs:{"outlined":"","striped":"","hover":"","items":_vm.transactions,"fields":_vm.tableHeaders,"head-color":"light","no-sorting":""},scopedSlots:_vm._u([{key:"order",fn:function(ref){
                            var item = ref.item;
                            var index = ref.index;
return _c('td',{staticClass:"text-center"},[_vm._v(_vm._s((index+1) + (_vm.activePage-1)*_vm.limit))])}},{key:"platform",fn:function(ref){
                            var item = ref.item;
return _c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.platform))])}},{key:"content",fn:function(ref){
                            var item = ref.item;
return _c('td',{staticClass:"text-left"},[_vm._v(_vm._s(item.cycle_type)+" : "+_vm._s(item.cycle)+", Min Value: "+_vm._s(item.min_amount.toLocaleString())+" "+_vm._s(item.amount_unit))])}},{key:"updateAt",fn:function(ref){
                            var item = ref.item;
return _c('td',{staticClass:"text-left"},[_vm._v(_vm._s(_vm._f("moment")(item.updated_at,"YYYY-MM-DD HH:mm")))])}}])}),_c('div',[_c('CPagination',{attrs:{"activePage":_vm.activePage,"pages":_vm.pages,"align":"end"},on:{"update:activePage":[function($event){_vm.activePage=$event},_vm.pushPage],"update:active-page":function($event){_vm.activePage=$event}}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }